import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import AvoidDeliveryDelaysFeatured from 'images/featured-image/how-to-avoid-delivery-delays-featured.png';
import AvoidDeliveryDelaysImg from 'images/blog-images/how-to-avoid-delivery-delays.png';
import ShipkooDeliveryImg from 'images/blog-images/shipkoo-delivery.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query HowToAvoidDeliveryDelaysImg {
      file(relativePath: { eq: "how-to-avoid-delivery-delays.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="how to avoid delivery delays"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How to Avoid Delivery Delays | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-to-avoid-delivery-delays" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "How to Avoid Delivery Delays",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-to-avoid-delivery-delays" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: AvoidDeliveryDelaysFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-to-avoid-delivery-delays"
        title="How to Avoid Delivery Delays"
        date="2020-06-24"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              How to avoid delivery delays is an annoying question to every online seller. Ever since the advent of e-commerce, the ease of doing business has increased tremendously. However, starting an eCommerce venture has its challenges. One of the challenges is that business owners must be ready to provide shipping services to their customers once the product is being sold. However, the main shortcoming comes in the form of delivery times. Can online sellers handle the stringent delivery times that customers want? Avoiding delivery delays can make a big difference in realizing customer satisfaction and eventually enhancing the growth of your business.
            </p>

            <h5>Self-delivery</h5>

            <p>
              It is the mainstream delivery method out of the others. It can grasp the two core "products" and "logistics" of cross-border e-commerce, and many small and medium-sized businesses will also prefer this model. However, its timeliness is hard to be controlled, and it is difficult to guarantee the product delivery time. This may lead to bad shipping experience for consumers. 
            </p>

            <p className="no-bottom">
              Advantage:
            </p>

            <UnorderedList>
              <li>
                High flexibility and low storage fee
              </li>

              <li>
                No need to keep bulk inventory and deadstock
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Disadvantages:
            </p>

            <UnorderedList>
              <li>
                Low product ranking and low website exposure
              </li>

              <li>
                Hard to control the logistics timeliness
              </li>
            </UnorderedList>

            <p className="no-bottom">
              Summary:
            </p>
            <p>
              Although some people may think that logistics problems will easily lead to bad product reviews, Shipkoo’s Express Shipping Solution is a relatively stable shipping solution. We place test orders to confirm the actual shipping lead time of our existing routes regularly and also newly added shipping solutions to ensure what we promise to our customers. During the pandemic, a lot of packages are sent from China every day, so our special lines can quickly ship goods to destination countries without strict time limit requirements.
            </p>

            <Image src={AvoidDeliveryDelaysImg} className="" alt="avoid delivery delays"/>

            <p className="no-bottom">
              <strong>
                Avoiding delivery delays
              </strong>
            </p>

            <p className="no-bottom">
              You can use a variety of strategies to avoid delivery delays. These include:
            </p>

            <UnorderedList>
              <li>
                Update your inventory <br />
                It is obvious that some of your products are more popular. For this reason, you should update your inventory regularly to replace products that have already been delivered. It would be so frustrating for a customer to order a product that does not exist in your inventory. 
              </li>

              <li>
                Choose and prepare your warehouse <br />
                As your business grows, you will acquire more orders. Always prepare your warehouse in such a way that it is easy to dispatch products to their respective destinations. Start by arranging the most popular products first, followed by the less popular ones. Shipkoo’s <a href="/our-story/global-network">network</a> is closely intertwined. As an international Shipping service provider, Shipkoo crosses national borders on a daily basis to bring you affordable storage. No matter where your manufacturers are in, you can simply send your items to Shipkoo fulfillment centers.
              </li>

              <li>
                Acquire automated logistic software <br />
                Automation can go a long way in reducing delivery times. Although it might be expensive to acquire automated logistic software, it will help you avoid delivery delays. Automated software determines what products should be dispatched according to their order times.
              </li>

              <li>
                Prepare in advance for the holidays <br />
                Businesses often witness increased orders and sales during the holidays. Unfortunately, most couriers do not work during public holidays. Therefore, you should make alternative arrangements to deliver orders in good time. To avoid delivery delays, always dispatch your orders before the public holidays commence. <a href="/resources/holiday-shipping/">Check here</a> for more information.
              </li>
            </UnorderedList>

            <Image src={ShipkooDeliveryImg} alt="shipkoo delivery"/>

            <p className="no-bottom">
              <strong>
                Conclusion:  
              </strong>
            </p>

            <p>
              Indeed, the best way to avoid delivery delays is to partner with a one-stop shipping solution provider  like Shipkoo. By working with Shipkoo, you do not have to worry about delivery times. You can rest assured that all your goods will be delivered to respective customers, promptly. As you focus on your sales, Shipkoo focuses on your back-end shipping & fulfillment issues. The company provides shipping, custom clearance, fulfillment and dropshipping, all in one roof. 
            </p>
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;